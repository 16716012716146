<template>
  <v-container class="own-page pb-12">
    <v-card>
      <v-skeleton-loader :loading="is_loading" height="150" type="article">
        <div>
          <template v-if="!error">
            <v-card-text v-html="page.content"></v-card-text>
            <v-card-actions v-if="is_admin">
              <span v-if="page.is_active" style="color:green;">{{ $t("Published") }}</span>
              <span v-if="!page.is_active" style="color:red;">{{ $t("NotPublished") }}</span>
              <v-spacer></v-spacer>

              <v-btn color="primary" :to="'/page/edit/' + page.id" class="text-none">{{ $t("EditPage") }}</v-btn>
            </v-card-actions>
          </template>

          <v-card-text v-if="not_found" class="text-center pt-10 pb-10">
            <v-icon style="font-size: 70px" class="mb-4">$vuetify.icons.farFrown</v-icon>
            <h1 class="mb-5">{{ $t("PageNotFound") }}</h1> 
            <p v-html="$t('PageNotFoundDesc')"></p>

            <v-btn v-if="is_admin" class="mt-5 text-none" color="primary" :to="'/page/new?name=' + url_name">{{ $t("CreatePage") }}</v-btn>
          </v-card-text>

          <error-page v-if="!not_found" :error="error" home="/"></error-page>
        </div>
      </v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
        "PageNotLoaded": "Page could not be loaded",
        "PageNotLoadedDesc": "The page could not be loaded.",
        "PageNotFound": "Page not found!",
        "PageNotFoundDesc": "The page you were trying to reach does not exist. The page may have been moved or removed.<br /> Please check the URL one more time.",
        "EditPage": "Edit Page",
        "NewPage": "New page",
        "CreatePage": "Create this page",
        "NotPublished": "Not published!",
        "Published": "Published"
    },
    "sv": {
        "PageNotLoadedFound": "Sidan kunde inte laddas",
        "PageNotLoadedDesc": "Sidan kunde inte laddas korrekt. Detta kan bero på att den inte finns eller att ett fel inträffade när den skulle laddas.",
        "PageNotFound": "Sidan kunde inte hittas!",
        "PageNotFoundDesc": "Den sida du försökte nå existerar inte. Sidan kan ha flyttats eller tagits bort.<br /> Vänligen kontrollera webbadressen en extra gång.",
        "EditPage": "Redigera sidan",
        "NewPage": "Ny sida",
        "CreatePage": "Skapa denna sida",
        "NotPublished": "Ej publicerad!",
        "Published": "Publicerad"
    }
    }
</i18n>

<script>
import { systemPagesService } from "../../_services";
import { mapState } from "vuex";

export default {
  name: "system-page-form",
  metaInfo() {
    return {
      title: this.page.title,
      meta: [
        {
          property: "og:title",
          content: this.page.title + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: this.page.summery,
          vmid: "og:description"
        },
        {
          name: "description",
          content: this.page.summery,
          vmid: "description"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  data: () => ({
    is_loading: false,
    error: null,
    is_admin: false,
    not_found: false,
    url_name: "",

    page: {
      title: "",
      content: "",
      summery: "",
      id: null
    }
  }),
  created: function() {
    if (!this.$route.params.name) {
      this.$router.push("/");
      return;
    }
    this.url_name = this.$route.params.name;

    this.load(this.$route.params.name);
    this.is_admin = this.$store.getters["account/isInRole"]("Admin");
  },
  methods: {
    load(name) {
      var self = this;
      self.is_loading = true;
      self.error = null;

      systemPagesService
        .getPage(name)
        .then(function(response) {
          self.page = response.data;
          self.is_loading = false;
        })
        .catch(function(error) {
          self.handleError(error);
        });
    },
    handleError(error) {
      var self = this;
      self.error = error;
      self.is_loading = false;

      if (error.response && error.response.status == 404) {
        self.not_found = true;

        self.page = {
          title: self.$t("PageNotFound"),
          summery: self.$t("PageNotFoundDesc")
        };
      } else {
        self.page = {
          title: self.$t("PageNotLoaded"),
          summery: self.$t("PageNotLoadedDesc")
        };
      }
    }
  }
};
</script>
